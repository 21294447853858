import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../../components/common/Layout';
import { StaticTranslationProvider } from '../../contexts/StaticTranslationContext';
import LanguageContext from '../../contexts/LanguageContext';
import StrapiDataContext from '../../contexts/StrapiDataContext';
import { privacyPolicyPageStrings } from '../../resources/strings/strings-en';
import PrivacyPolicyPage from '../../components/pages/PrivacyPolicyPage';

const PrivacyPolicyPage_en = () => {
  const remoteData = useStaticQuery(remoteDataQuery);

  return (
    <StrapiDataContext.Provider value={remoteData}>
      <StaticTranslationProvider translations={privacyPolicyPageStrings}>
        <LanguageContext.Provider value="en">
          <Layout footer={false} simplified={true}>
            <PrivacyPolicyPage data={remoteData} />
          </Layout>
        </LanguageContext.Provider>
      </StaticTranslationProvider>
    </StrapiDataContext.Provider>
  );
};

const remoteDataQuery = graphql`
  query GetPrivacyPolicyPageContentEn {
    staticContent: strapiPrivacyPolicyPage {
      title {
        en
      }
      subtitle {
        en
      }
      intro {
        en {
          data {
            en
          }
        }
      }
      privacyPolicyText {
        en {
          data {
            en
          }
        }
      }
    }
    contactUsForm: strapiContactUsForm {
      budgetOptions {
        en
      }
      facebook {
        url
        icon {
          localFile {
            ...FluidImage
          }
        }
      }
    }
  }
`;

export default PrivacyPolicyPage_en;
